/*----Accueil-----*/

.approche{
	position: relative;
	margin:100px 0;
	padding: 10px 22%;
	min-height: 215px;
}

.approche h1{
	color: $secondary;
	margin-top:0;
}

.rouge{
	color: $primary;
}

.red_block{
	position: absolute;
	left: -785px;
	top:0;
}

.grey_block{
	position: absolute;
	right: -785px;
	top: 0;
}

.difference{
	margin: 200px 0 100px;
	position: relative;
	min-height: 896px;
}
.carrousel_freemode{
	position: relative;
	overflow: hidden;
	padding-bottom: 50px;
	max-width:1036px;
}

.carrousel_freemode .swiper-slide{
	overflow: hidden;
}

.carre_gris{
	background-color: #F0F0F0;
	position: absolute;
	width: 85%;
	height: 100%;
	left:0;
	right:0;
	margin: 0 auto;
	display: block;
	z-index:-1;
}

.check{
	position: absolute;
	top:-75px;
	left: 19%;
	mix-blend-mode: multiply;
	z-index:2;
}

.diffrence_flex{
	display: flex;
	padding-left: 15%;
	padding-top: 100px;
}

.text_difference{
	flex: 0 0 23%;
	padding-right: 42px;
	padding-top: 35px;
}

.difference_slide{
	height: 657px;
	width: 300px;
	margin-left: 20px;
	overflow: hidden;
	position:relative;
	padding-bottom: 20px;
	transition: 1s;
}
.carrousel_freemode .swiper-slide-active .difference_slide,
.carrousel_freemode .swiper-slide-active{
	width: 450px!important;
	margin-left:0;
}

.img_difference{
	position: absolute;
	height: 97%;
	width: 100%;
	z-index:-2;
	transition: 1s;
}
.mask_difference{
	background-color: rgba($secondary, 0.82);
	position: absolute;
	height: 97%;
	width:100%;
	transition: 1s;
	z-index:-1;
}

.carrousel_freemode .swiper-slide-active .mask_difference{
	width: 350px;
	height: auto;
	bottom: -20px;
	left: 0px;
	left:20px;
	padding:20px;
}

.carrousel_freemode .swiper-slide-active .text_difference_slide{
	left: 20px;
	max-width: 275px;
	bottom: 10px;
}
.text_difference_slide{
	position: relative;
	top:65%;
	left: 20px;
	max-width:75%;
	transition: 1s;
}

.text_difference_slide h2{
	color: white;
	max-width:240px;
	transition: 1s;
}

.carrousel_freemode .swiper-slide-active .text_difference_slide h2{
	max-width: 206px;
}

.hidden_text_difference{
	opacity: 0;
	transition: 1s;
}

.hidden_text_difference p{
	color: white;
}

.carrousel_freemode .swiper-slide-active .hidden_text_difference{
	opacity: 1;
}

.crochet{
	transition: 1s;
}

.carrousel_freemode .swiper-slide-active .crochet{
	position: absolute;
	right: -10px;
	top:0;
}

.swiper-pagination-progressbar{
	left: 0!important;
	top: initial!important;
	bottom: -30px!important;
	height: 10px!important;
	background: white!important;
	border-radius: 25px!important;
}

.swiper-pagination-progressbar-fill{
	background: $primary!important;
	border-radius: 25px!important;
}



.img_carrousel2{
	height: 540px;
	width: 100%;
	margin-top:100px;
}

.swiper-expertise .line_text {
    text-shadow: -1px 0 $secondary, 0 1px $secondary, 1px 0 $secondary, 0 -1px $secondary;
    color: $gray_toiture;
    transition: 1s;
}

.swiper-expertise .swiper-slide-active .line_text.full{
	color: $secondary;
}

.lexpertise .rouge{
	padding-left: 15%;
}

.swiper-expertise h2{
	margin: 0;
}

.pad_slide{
	padding-left: 30%;
}

.branche_expertise{
	padding-left: 15%;
}

.expertise{
	width: 526px;
	position: relative;
}

.expertise .button_carrousel{
	justify-content: space-between;
}

.expertise .button_carrousel p{
	max-width:initial;
	margin-top:25px;
	color: $primary;
}

.expertise .button_carrousel svg{
	right:0;
}

.line_expertise{
	height: 3px;
	width: 100%;
	margin-bottom: 25px;
	background-color: #F0F0F0;
}

.button_carrousel_rouge{
	background-color: $primary;
	width: 100%;
	height: 100px;
	display: flex;
	max-width: 526px;
	padding: 0 20px;
	margin-top:40px;
	transition: 1s;
}

.no-touchevents .button_carrousel_rouge:hover{
	background-color: $secondary;
}

.button_carrousel_rouge p{
	color: white;
	text-align:center;
	margin-left: 25px;
	margin-top: 33px;
	font-weight:600;
}

.financement{
	padding: 0 15%;
	position: relative;
	top:-50px;
	z-index:2;
}

.carre_gris_financement{
	background-color: $secondary;
	position:absolute;
	bottom: 20%;
	right:-65px;
	height: 215px;
	width:15%;
	transform: skewX(-30deg);
}
.text_financement{
	margin-top: 150px;
}

.text_financement h2{
	color: $secondary!important;
}

.text_financement h2 .rouge{ 
	color: $primary!important;
}

.grid {
	position: relative;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(8,1fr);
}

.grid__item {
	position: relative;
	will-change: transform;
	grid-column: var(--c);
	grid-row: var(--r);
}

.grid__item-img {
	position: relative;
	width: 100%;
	height: auto;
	aspect-ratio: 1;
	background-size: cover;
	background-position: 50% 50%;
	will-change: transform, opacity;
}

.cover {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	z-index:999;
}

.cover p{
	color: white;
	margin-top: 50px;
	max-width: 600px;
}
.cover h2{
	margin-bottom:0;
}

.pros{
	margin-top: 100px;
	background-color: $secondary;
	overflow: hidden;
	clip-path: inset(0 0 0 0);
}

.pros .button_carrousel p{
	margin-top:19px;
	transition: 1s;
}

.line_cover{
	text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    color: transparent;
}

.employeur{
	display: flex;
	margin: 100px 0;
}

.text_employeur{
	width:50%;
	padding-top: 50px;
    padding-left: 15%;
    position: relative;
    z-index:2;
}

.img_employeur{
	width:50%;
	height: 744px;
}

.employeur .full{
	color: $primary;
}
.text_employeur .font95{
	margin: 0;
	color: $secondary;
}

.text_employeur .carre_plein{
	background-color: $primary;
	color: white;
}

.text_employeur p{
	max-width:619px;
	margin-top:50px;
}

.text_employeur .button_carrousel p {
    max-width: 201px;
    margin-top: 21px;
}

.nouvelles{
	padding: 0 10%;
}

.img_blog{
	height: 263px;
	width:100%;
}

.blog_solo{
	margin: 0 20px;
	background-color: white;
}

.container_blogue_accueil{
	background-color: #F0F0F0;
	padding: 140px 80px;
}


.nouvelles{
	position: relative;
	margin:100px 0;
}

.title_nouvelle{
	position: absolute;
	top: -50px;
    left: 14%;
}

.pad_nouvelle{
	padding: 40px 40px 65px;
	min-height: 460px;
	position: relative;
}

.pad_nouvelle .button{
	position: absolute;
	bottom:40px;
	left: 40px;
	color: $primary;
}

.date{
	color: $primary;
	margin: 0;
	text-transform: uppercase;
}

.title-blogue{
	color: $secondary;
	margin: 10px 0 20px;
}

.pad_nouvelle .button svg{
	position: relative;
	left:20px;
	top:10px;
}

.title_nouvelle2{
	position: absolute;
	bottom: -60px;
	left:0;
	right: 0;
	margin: 0 auto;
	display: block;
}

.nouvelles .button_carrousel{
	position: absolute;
	right: 15%;
    bottom: 20px;
}

/*-----Blogue------*/

.filter-blogue{
    display: inline-block;
}


.filter-blogue ul{
    display: flex;
    padding: 0;
}

.filter-blogue ul li{
    list-style: none;
    margin-right: 20px;
}

.flex-container.wrap{
    display: flex;
    flex-wrap: wrap;
}

.flex-3{
    flex: 0 0 33%;
}

.img_resume{
    margin-bottom: 40px;
}


.filter-blogue ul li a.is-active,
.no-touchevents .filter-blogue ul li a:hover{
    color: $primary;
}

.blog-filter{
    transition: 1s;
    visibility: visible;
    height: 100%;
    width:100%;
    opacity: 1;
}

.blog-filter.is-hidden{
    visibility: hidden;
    opacity: 0;
    width:0;
    height:0;
    flex: 0;
    transition: visibility 0s, opacity 0.5s linear; 
}

.flexible,
.flex-container{
    display: flex;
}

.col-mid{
    flex: 0 0 50%;
}
.mobile-appear{
    display: none;
}

.padding-content{
    margin: 50px 0;
}

.padding-content a{
	color:$primary;
}

.pad-text{
    padding-right:20%;
}

.container-button{
    margin-top:40px;
}

.text-blog{
	padding-right:10%;
}

.btn{
	color: $secondary;
}

.title-filter > h2{
	color:$secondary;
}

.pad-blog h3{
	color: $primary;
}

.page__inner .max-content{
	padding: 0 15%;
}	
.pad-text-right{
	padding-left: 20%;
}

.page h2{
	color: $primary;
}

.img-teaser{
	height: 250px;
	margin-bottom:20px;
}

.share-single{
	text-align:center;
}

.width_button p{
	max-width:100px!important;
}

/*----Toiture-----*/

.services_toiture{
	margin: 170px 0 0px;
}
.mask_difference_rouge{
    background-color: $primary;
    opacity: 1;
    mix-blend-mode: multiply;
    z-index: -1;
    position: absolute;
    height: 97%;
    width: 30%;
    transition: 1s;
}

.swiper-slide-active .mask_difference_rouge{
	width:100%;
}	

.difference_service .crochet{
	opacity:0;
	visibility: hidden;
	transition: 1s;
}

.swiper-slide-active .difference_service .crochet{
	opacity:1;
	visibility: visible;
}

.difference_service h2{
	transform: rotate(-90deg);
	color: white!important;
	bottom: 235px;
	position:relative;
}

.swiper-slide-active .difference_service h2{
	transform: rotate(0deg);
}

.difference_service .text_difference_slide {
    left: -50px;
    max-width: 65%;
    bottom:-50px;
    transition: 1s;
    min-height: 365px;
}

.text_difference_slide h2{
	color: white;
}

.swiper_difference2 .carrousel_freemode .swiper-slide-active{
	width: 370px!important;
}

.swiper-slide-active .difference_service .text_difference_slide {
    bottom: 195px;
    min-height: auto;
    top:55%;
}
.swiper-slide-active .difference_service .crochet{
	position:initial;
	right:initial;
}

.text_services_difference{
	flex: 0 0 36%;
	padding-right:5%;
}

.text_services_difference .button_carrousel p {
    max-width: 139px;
}

.gris{
	color: $secondary;
}

.swiper_difference2 .swiper-pagination-progressbar,
.swiper_difference3 .swiper-pagination-progressbar{
	background: #EDEDED!important;
}

.financement2 {
    padding: 100px 0 100px 10%;
    position: relative;
    z-index: 2;
}

.precision{
	position: relative;
	width:100%;
	min-height:757px;
	padding-top:50px;
	overflow: hidden;
}

.mask_video{
	width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index:-1;
  background-color:black;
  opacity:0.7;
}
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index:-2;
}

.text_precision{
	position: absolute;
	left:0;
	top:100px;
	max-width:45%;
}

.didyouknow{
	position: absolute;
	max-width:506px;
	top: 100px;
	right: 15%;
}

.pad_precision{
	padding-left:33%;
}

.pad_precision p{
	color: white;
}

.text_precision .button_parallelogram {
    padding-left: 36%;
}
.text_precision .parallelogram_container {
   	margin-top:60px;
}

.didyouknow h2{
	margin-bottom:0;
}
.didyouknow h3{
	margin-top:0;
}
.flexible_carrousel{
	display: flex;
}
.img_carrousel_realisation{
	height:600px;
	width:100%;
	z-index:-1;
}
.realisation_toiture{
	padding: 100px 0 100px 15%;
}
.text_flex_carrousel{
	margin-right:-180px;
} 
.text_flex_carrousel2{
	margin-right:-100px;
}
.button_carrousel p {
    max-width: 200px;
    margin-top:19px;
}

.text_engagement{
	max-width:873px;
	padding-left: 17%;
	padding-top: 95px;
}
.text_engagement .button_carrousel p {
    max-width: 237px;
    margin-top: 7px;
}
.text_engagement h2{
	text-transform: uppercase;
}
.checked_toiture{
	position: absolute;
	right: 12%;
    margin-top: -90px;
}
.difference_block{
	position: relative;
	margin: 240px 5% 40px;
	padding: 100px;
	min-height:870px;
}
.difference_block .mask_difference{
	height: 100%;
	top:0;
	left:0;
}
.back_difference{
	position: absolute;
	height: 100%;
	width:100%;
	top:0;
	left:0;
	z-index:-2;
}
.parallel_gris {
    width: 1097px;
    height: 282px;
    transform: scale(3.5);
    position: relative;
    top: 120px;
    right: -365px;
}
.financement_logo{
	padding-right:20%;
}
.difference_titre1{
	margin-bottom:0;
	margin-top:0;
}
.difference_titre2,
.difference_titre3{
	margin:0;
}
.difference_titre2{
	color: white!important;
	margin-left:10px;
}
.difference_titre3{
	background-color: $primary;
	color: white!important;
}
.diffrence_foreach{
	max-width: 400px;
	position: relative;
	padding-right: 26px;
    padding-bottom: 1px;
    margin: 0px 53px 20px;
    min-height:126px;
    display: flex;
  	align-items: center;
}
.diffrence_foreach p{
	color: white;
	text-transform: uppercase;
}

.diffrence_foreach:after{
	content:"";
	height: 3px;
	width:100%;
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: #F0F0F0;
	opacity: 0.3;
}
.slogan{
	color: white!important;
	max-width: 381px;
	padding-left: 60px;
    margin-top: 23%;
}

.parelelle_difference{
	position: absolute;
	bottom: 150px;
    right: -569px;
}
.distinction_toiture{
	background-color: $primary;
	margin: 0 5% 100px;
}
.text_distinction{
	padding-left: 100px;
	padding-top:100px;
	max-width:52%;
}
.text_distinction h2,
.text_distinction h3{
	margin: 0;
}

.text_distinction h3{
	margin: 10px 0;
}
.text_distinction h3,
.text_distinction p{
	color: white;
}
.dore{
	color: $gold!important;
	font-weight:600!important;
	letter-spacing:5px;
}
.flex_distinction{
	display: flex;
}

.distinction_logo{
	margin: -50px auto -90px;
}
.distinction_logo2{
	margin: -85px auto;
	max-height:530px;
}

.materiaux{
	padding: 50px 15% 100px;
	position:relative;
}

.flex_row{
	display: flex;
	margin-bottom: 20px;
}

.image_row{
	flex:0 0 23%;
	min-height: 320px;
	margin-left:20px;
}

.bloc_gris{
	background-color: $secondary;
	padding: 40px;
}
.bloc_gris p,
.bloc_rouge p,
.bloc_gris h2{
	color: white!important;
}

.bloc_rouge{
	background-color: $primary;
	margin-left:20px;
	padding: 85px 40px 0;
}

.composantes{
	background-color: $gray_back;
	padding: 100px 0 0 8.5%;
}

.composantes img{
	margin-bottom: -10px;
}
.text_composantes h2{
	color: $secondary;
}
.text_composantes{
	padding-left:17%;
	margin-bottom: 50px;
}
.etape_titre{
	color: $primary;
	font-weight:600;
	text-transform: uppercase;
}
.chiffre_etape{
	background-color: $primary;
	border-radius: 50%;
	padding: 10px;
	height: 40px;
	width:40px;
	margin:0 auto;
}
.chiffre_etape p{
	color: white;
	text-align: center;
    margin-top: -3px;
}

.prochangement p{
	max-width: 760px;
}

.service_toiture h2{
	bottom: 65px;
    position: relative;
}

.swiper-slide-active .service_toiture h2{
	bottom:0;
}
/*--------Revêtement-------*/

.services_revetement .difference_service .text_difference_slide {
    min-height: 556px;
}  

.services_revetement .text_difference_slide h2 {
    min-width: 531px; 
    position: relative;
    left: -160px; 
} 

.marg_different{
	left: -170px!important; 
}
.services_revetement .swiper-slide-active .difference_service .text_difference_slide {
    min-height: initial;
}  


.services_revetement .swiper-slide-active .text_difference_slide h2 {
    left:0!important;
    bottom:0;
}   

.swiper_difference3 .difference_slide{
	width: 360px;
}

.align{
	display: inline;
}

.difference_titre3{
	margin: 30px 0 60px;
    max-width: 1179px;
    padding: 0 5px;
}

.max_width_different{
	max-width: 388px;
	margin-top: 10px;
}

.modif{
	max-width: 970px;
}

.slogan_marg{
	margin-top:19%;
}

.bottom_dif{
	bottom: 125px;
}

.max_width_bloc{
	flex: 0 0 40%;
	margin-right:10px;
}

.image_row_container{
	padding: 0 10px;
	min-width:29%;
}
.image_row_container_inside{
	position: relative;
	height:100%;
	width: 100%;
	min-height:370px;
	overflow: hidden;
}

.image_materiaux{
	position: absolute;
	height:100%;
	width:100%;
	z-index:-2;
}

.mask_materiaux{
	position: absolute;
	height:100%;
	width:100%;
	z-index:-1;
	background-color: $primary;
	mix-blend-mode: multiply;
	opacity:0.8;
	top:100%;
	transition:1s;
}

.text_hover{
	opacity: 0;
	visibility: hidden;
	position:relative;
	top:50px;
	padding: 25px 20% 0 10%;
	transition: 1.5s;
}
.text_hover h3{
	text-transform: uppercase;
}

.text_hover h3,
.text_hover p{
	color: white!important;
}
.image_row_container_inside:hover .text_hover{
	top:10%;
	opacity:1;
	visibility: visible;
}

.image_row_container_inside:hover .mask_materiaux{
	top:0;
}

.name_materiaux{
	clip-path: polygon(0% 1%,100% 0%,90% 100%,0% 100%);
	background-color: rgba($secondary, 0.6);
	width:70%;
	padding: 3px 0;
	transition: 1s;
}
.image_row_container_inside:hover .name_materiaux{
	background-color: rgba($primary, 0.6);
}

.name_materiaux p{
	margin:0;
	text-transform: uppercase;
	color: white!important;
	padding-left:10%;
}

.max_width_bloc_rouge{
	max-width:526px;
	padding: 100px 40px;
	margin-left:10px;
	text-align:center;
}
.bloc1,
.bloc3_2{
	padding-right:0;
}

.bloc2_0,
.bloc3_0{
	padding-left:0;
}
.bloc3_0{
    flex: 0 0 42%;
}

.cles{
	padding: 100px 0 1px;
	position:relative;
}

.gray_back{
	position: absolute;
	height:100%;
	width: 100%;
	background-color: $gray_back;
	z-index:-1;
	top:0;
}

.title_cles{
	max-width: 600px;
	margin-left:12%;
	margin-bottom:70px;
}
.etape_1,
.etape_2,
.etape_3,
.etape_4{
	display: flex;
	margin-bottom:100px;
}
.img_step{
	width:100%;
	min-height: 890px;
}
.step1,
.step3 {
    padding-left: 15%;
    flex: 0 0 45%;
    margin-right: 5%;
}
.step3{
	padding-top:100px;
	padding-right: 5%;
}

.step2,
.step4{
	flex: 0 0 50%;
	padding: 0 15% 0 10%;
}
.etape{
	position: relative;
}

.etape .uppercase{
	font-weight:600;
}
.fleche{
	position: absolute;
	left: -51px;
	top:0;
}
.uppercase{
	text-transform: uppercase;
	margin-bottom:0;
}
.title_cles h2{
	text-transform: uppercase;
}
.title_cles h3{
	color: $secondary;
}
.same_line{
	position: relative;
	left: -54px;
}
.same_line .chiffre_etape{
	position: absolute;
	left: -60px;
    top: -11px;
}

.same_line h3{
	text-transform: uppercase;
}
.same_line h3{
	color: $primary!important;
	font-size: 18px;
}
.word_red{
	display: flex;
	margin-bottom:15px;
}
.word_red p{
	color:white;
	padding: 10px 30px;
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
}
.word1{
	background-color: $primary;
	clip-path: polygon(0% 1%,100% 0%,90% 100%,0% 100%);
}

.word2{
	background-color: $primary;
	clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
}
.word3{
	background-color: $primary;
	clip-path: polygon(7% 0%, 100% 0%, 93% 100%, 0% 100%);
}
.step3 .same_line {
    position: relative;
    left: 0px;
}

.difference_titre1.font40.align{
	position: relative;
	top:-10px;
}
.mobile_appear{
	display:none;
}

.swiper_difference2 .swiper-pagination,
.swiper_difference .swiper-pagination,
.swiper_difference3 .swiper-pagination {
	bottom: -15px!important;
}

.page-template-terrasse_patio .bloc2_1{
	width: 120%;
}
.modif_rouge{
	min-width:40%;
	display: flex;
  	align-items: center;
}
.pad_step{
	padding-top:100px;
}
.logo_rouge {
    position: absolute;
    top: 0px;
    left: 36%;
    z-index: 3;
    max-width:195px;
}

.margin-top_different{
	margin-top:-100px;
	position: relative;
	z-index:6;
}

.logo_materiaux{
	position: absolute;
    top: 0;
    right: 17%;
    z-index: 3;
}

.a_gauche{
	right: initial;
	left:35%;
	max-width:195px;
}

.img_video{
	position: absolute;
	z-index:-2;
	height:100%;
	width:100%;
	top:0;
}
/*----Financement-----*/

.avantages_financement{
	position:relative;
}
.pad_avantages{
	margin: -200px 20% 100px;
    max-width: 680px;
    float: right;
}

.pad_avantages h2{
	margin-left:-50px;
}

.rouge_avantage{
	position: relative;
	left: -380px;
	top:180px;
}
.page-template-financement .rouge_avantage{
	top:210px;
}

.gris_avantage{
	position: relative;
	right:-760px;
	top:0;
	float: right;
}

.step_foreach{
	margin-bottom: 40px;
}

/*----À propos-----*/
.suite_president{
	padding: 0px 14.7% 0;
	position: relative;
	margin-top:-150px;
}

.suite_texte{
	max-width: 560px;
}

.image_1_president{
	position: absolute;
	height: 645px;
	width: 491px;
	right: 23%;
	top:35px;
}

.image_2_president{
	position: absolute;
	height: 470px;
	width: 705px;
	right: 0;
	border:10px solid white;
	top:475px;
	z-index:2;
}

.image_1_president_propos{
	position: absolute;
	height: 645px;
	width: 491px;
	right: 23%;
	top:251px;
}	

.image_2_president_propos{
	position: absolute;
	height: 470px;
	width: 705px;
	right: 0;
	border:10px solid white;
	top:730px;
	z-index:2;
}

.pad_text_right{
	padding-right:10%;
}

.pad_text_left{
	padding-left:10%;
}

.part2{
	margin-top: 50px;
}

.signature{
	position: absolute;
	bottom: -100px;
    z-index: -1;
    right:15%;
}

.distinctions_propos{
	padding: 230px 10% 100px;
}

.box_rouge_distinctions{
	background-color: $primary;
	display: flex;
	padding: 80px 6%;
	position: relative;
}

.logo_1_distinctions{
	position: absolute;
	top: -127px;
    right: 190px;
}
.logo_2_distinctions{
	position: absolute;
	top: 122px;
    right: -60px
}

.distinctions_propos h2,
.certifications_propos h2{
	color: $gold;
	margin-bottom:10px;
}

.distinctions_propos > h2,
.certifications_propos > h2{
	padding-left: 6%;
	letter-spacing: 8px;
	text-transform: uppercase;
}
.text_distinctions_rouge{
	width: 715px;
}
.text_distinctions_rouge h2{
	font-weight: 400;
	margin-bottom: 60px;
}

.text_certifications_propos h2{
	font-weight:400;
}

.text_distinctions_rouge .bold,
.approche_propos h2{
	text-transform: uppercase;
}

.text_distinctions_rouge p{
	color: white;
}

.certifications_propos{
	padding: 50px 0;
}

.box_gris_certifications{
	background-color: $gray_blue;
	display: flex;
	min-height: 534px;
	padding: 80px 6%;
	position:relative;
}

.text_certifications_propos{
	width: 328px;
}

.logo_certifications_1,
.logo_certifications_2,
.logo_certifications_3,
.logo_certifications_4,
.logo_certifications_5{
	position: absolute;
}

.logo_certifications_1{
	top: -125px;
    right: 928px;
}

.logo_certifications_2{
	right: 675px;
    top: 124px;
}
.logo_certifications_3{
	right: 422px;
    top: -125px;
}
.logo_certifications_5{
	right: 171px;
    top: 126px;
}
.logo_certifications_4{
    right: -75px;
    top: -122px;
}

.logo_certification_4_texte{
    position: absolute;
    right: 122px;
    top: 176px;
    height: 180px;
    width: 284px;
    text-align: center;
}

.logo_certification_4_texte h2{
	color:$secondary;
	text-transform: uppercase;
}

.text_certifications_propos h2{
	margin-top: 135px;
}

.approche_propos{
	text-align: center;
	max-width: 1009px;
	margin: 100px auto;
	display: block;
}

.bouton_approche{
	text-align: left;
	width:250px;
	margin: 0 auto;
	display: block;
}
.team{
	background-color: $gray_back;
	padding: 100px 10%;
}

.team h2{
	text-align:center;
	margin-bottom: 40px;
	text-transform: uppercase;
}

.flexible_team{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.flex_team{
	flex: 0 0 33%;
	width:303px;
}

.text_team{
	max-width:303px;
	margin: 40px auto;
}

.text_team p{
	margin: 0;
}

.img_personne{
	height: 303px;
	width:303px;
	margin: 0 auto;
}

.map_container{
	position: relative;
}

.title_map h2{
	color: white;
	text-transform: uppercase;
}
.map{
	width:100%;
	z-index:-1;
}

.title_map{
	position: absolute;
	top:0;
	left:0;
	background-color: rgba($secondary, 0.9);
	transform:skew(-30deg);
	padding: 30px 150px;
    margin-left: -54px;
}

.title_map h2{
	transform:skew(30deg);
}

/*.suite_texte span{
	font-weight:400;
}*/
.nom_president_rouge{
	color:$primary;
}

.text_team h3{
	color:$primary;
	margin-bottom: 0;
}
.description_team{
	margin-top:40px!important;
}

.carriere_propos{
	min-height:550px;
	width:100%;
	position:relative;
	display: flex;
  	justify-content: center;
  	align-items: center;
}

.back_carriere_propos{
	position:absolute;
	height:100%;
	width:100%;
	top:0;
	left:0;
	z-index:-2;
}

.mask_carriere_propos{
	background-color: rgba(0,0,0,0.8);
	position:absolute;
	height:100%;
	width:100%;
	top:0;
	left:0;
	z-index:-1;
}

.text_carriere_propos{
	text-align:center;
	padding: 0 5%;
}

.text_carriere_propos h3,
.text_carriere_propos .button_carrousel p{
	color: white;
}

/*----Carrière-----*/
.texte_suite_container{
	position:relative;
}

.suite_texte2{
	padding-left:15%;
	max-width:830px;
}
.suite_texte2 h2{
	color:$secondary;
}
.suite_texte2 h2 span{
	font-weight:bold;
}
.texte_suite{
	max-width:870px;
	padding-left:15%;
}

.suite{
	padding-top:100px;
	position:relative;
}
.texte_suite_container{
	padding-top:100px;
}
.page-template-carriere .image_2_president{
	top:530px;
}

.signature2{
	margin: 10px 10% 40px;
    z-index: -1;
    position: relative;
    display: block;
}

.securite_perspective_container{
	background-color: $secondary;
	padding-bottom:50px;
	margin-top:200px;
}

.securite_perspective{
	display: flex;
	justify-content: space-between;
	margin-bottom:100px;
}

.securite_perspective img{
	max-width:60%;
}

.img_perspective{
	margin-left:10%;
}

.securite_perspective h2,
.red_avantage h2{
	text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
	color:$secondary;
	text-transform: uppercase;
	margin-bottom:40px;
}

.red_avantage h2{
	color: $primary;
}

.securite_perspective p{
	color: white;
	max-width:700px;
}
.texte_droite{
	padding-right: 10%;
	padding-top:3%;
	min-height:534px;
}

.texte_gauche{
	padding-left:10%;
	min-height:534px;
}

.button_rouge{
	background-color: $primary;
	padding: 5px 15px;
	display: block;
	display: flex;
	max-width:325px;
	transition: 1s;
	margin-top:50px;
}

.no-touchevents .button_rouge:hover p{
	left: 40px
}


.no-touchevents .button_rouge:hover{
	background-color: $gold;
}

.button_rouge p{
	color: white;
	text-transform: uppercase;
	position: relative;
    top: 19px;
    left: 30px;
    transition: 1s;
}

.avantage{
	display: flex;
	justify-content: space-between;
	width:100%;
}

.img_avantage{
	height:534px;
	width:100%;
	flex:0 0 50%;
}

.red_avantage{
	flex: 0 0 50%;
	background-color: $primary;
	padding-left: 8%;
	display: flex;
  	justify-content: center;
  	align-content: center;
  	flex-direction: column;
}

.red_avantage h2{
	margin: 0;
}

.texte_foule{
	padding-left:15%;
}

.text_axe{
	max-width: 918px;
	float: right;
	margin-bottom:100px;
	padding-right:15%;
}

.text_axe h2{
	color: $secondary;
}

.foule{
	padding: 100px 0;
	position:relative;
}

.axe{
	margin:100px 0;
	position:relative;
	min-height:240px;
}

.texte_foule h2{
	text-transform: uppercase;
	margin-left:-52px;
}

.paralelle_rouge{
	transform:skew(-30deg);
	position: absolute;
	height: 220px;
	width:15%;
	left:-100px;
	top:185px;
	background-color: $primary;
}

.paralelle_axe{
	transform:skew(-30deg);
	position: absolute;
	height: 75%;
	width:50%;
	left:-100px;
	top:23px;
	background-color: $primary;
}

.paralelle_foule{
	transform:skew(-30deg);
	position: absolute;
	height: 70%;
	width:45%;
	right:-200px;
	top:100px;
	background-color: $secondary;
}

.paralelle_gris{
	transform:skew(-30deg);
	position: absolute;
	height: 220px;
	width:10%;
	left:47%;
	top:85px;
	background-color: $secondary;
	z-index:-1;
}
.mobile_appear{
	display: none;
}

.formulaire{
	padding: 100px 15%;
}

.formulaire input{
	height:60px;
}	

.margin_distinction{
	padding-top: 43px;
}

.mobile_hide{
	display: flex;
	justify-content: space-between;
	width:100%;
}

.nouvelles .button_carrousel p{
	max-width: 212px;
}

.step_foreach a{
	color: $primary;
}

@media screen and (max-width: 1900px) {
	.certifications_propos{
		padding: 50px 0;
	}

}
@media screen and (max-width: 1850px) {
	.branche_expertise {
	    padding-left: 20%;
	}
	.texte_suite{
		padding-right:10%;
	}
}	
@media screen and (max-width: 1800px) {
	.rouge_avantage {
	    left: -425px;
	}
	.logo_certifications_1, .logo_certifications_2, .logo_certifications_3, .logo_certifications_4, .logo_certifications_5 {
	    transform: scale(0.8);
	}
	.logo_certifications_5{
		right: 123px;
    	top: 74px;
	}
	.logo_certifications_3 {
	    right: 320px;
	}
	.logo_certifications_2 {
	    right: 514px;
	    top: 77px;
	}
	.logo_certifications_1 {
	    right: 711px;
	}
}

@media screen and (max-width: 1700px) {
	.lexpertise .rouge {
	    padding-left: 10%;
	}
	.pad_slide {
	    padding-left: 19%;
	}
	.text_financement{
		margin-left:40px;
	}
	.page .max-content{
		padding: 0 10%;
	}	
	.all_post .max-content{
		padding: 0!important;
	}
	.diffrence_flex{
		padding-left: 10%;
	}
	.realisation_toiture {
	    padding: 100px 0 100px 10%;
	}
	.materiaux {
	    padding: 50px 10% 100px;
	}
	.rouge_avantage {
	    top: 20px;
	}
	.page-template-financement .rouge_avantage {
		top:226px;
		left: -656px;
	}	
	.certifications_propos>h2, .distinctions_propos>h2 {
    	letter-spacing: 2px;
	}
	.logo_materiaux,
	.a_gauche{
	    right: 8%;
	    left:initial;
	}
	.paralelle_axe{
		width:40%;
	}

	.page-template-a_propos .suite_president{
		padding: 0px 8% 0;
	}
	.gris_avantage {
	    right: -800px;
	}
}	

@media screen and (max-width: 1600px) {
	.grey_block, .red_block {
	    position: relative;
	    top: 0;
	}
	.approche h1{
		margin: 0;
	}
	.text_engagement {
	    padding-top: 40px;
	}
	

	.logo_certification_4_texte {
	    top: 200px;
	}
	.logo_materiaux {
	    right: 3%;
	}
	.image_1_president,
	.image_1_president_propos{
		position:relative;
		top:0;
		left:15%;
	}

	.image_2_president,
	.image_2_president_propos{
		position:relative;
		top:-115px!important;
		left:16%;
	}
	.texte_suite {
	    max-width: 75%;
	    padding-left: 15%;
	}
	.signature {
	    position: relative;
	    bottom: initial; 
	    display: block;
	}
	.securite_perspective_container{
		margin-top:100px;
	}
	.distinctions_propos {
	    padding: 100px 0;
	}
}

@media screen and (max-width: 1560px) {
	.diffrence_flex,
	.lexpertise .rouge {
	    padding-left: 5%;
	}
	.lexpertise .rouge{
		padding-right: 5%;
	}
	.carre_gris {
	    width: 95%;
	}
	.swiper-pagination-progressbar {
	    bottom: -10px!important;
	}
	.pad_slide {
	    padding-left: 10%;
	}
	.branche_expertise {
	    padding-left: 10%;
	}
	.text_employeur,
	.diffrence_flex {
	    padding-left: 5%;
	}
	.nouvelles {
	    padding: 0 5%;
	}
	.container_blogue_accueil {
	    padding: 140px 20px;	
	}
	.nouvelles .button_carrousel {
	    right: 7%;
	}
	.check {
	    left: 7%;
	}
	.page .max-content{
		padding: 0 5%;
	}	
	.didyouknow {
	    right: 5%;
	}
	.realisation_toiture {
	    padding: 100px 0 100px 5%;
	}
	.difference_block {
	    margin: 240px 4% 40px;
	}
	.slogan {
	    padding-left: 20px;
	}
	.difference_block {
	    padding: 50px 3%;
	}
	.parelelle_difference {
	    bottom: 256px;
	}
	.distinction_toiture {
	    margin: 0 4% 50px;
	}
	.approche .button_carrousel{
		margin-top: -100px;
	}
	.materiaux {
	    padding: 50px 5% 100px;
	}
	.composantes {
	    padding: 100px 0 100px 3.5%;
	}
	.page-template-toiture .prochangement div{
		max-width:760px;
	}
	.bottom_dif {
	    bottom: 96px;
	}
	.title_cles {
	    margin-left: 75px;
	}
	.step1, .step3 {
    	padding-left: 130px;
 	}   
 	.text_distinctions_rouge {
	    width: 43%;
	}	
	.page-template-financement .rouge_avantage {
	    left: -629px;
	}
	.text_certifications_propos h2 {
	    margin-top: 322px;
	}
	.img_carrousel2{
		height: 620px;
	}
}	

@media screen and (max-width: 1450px) {
	.pad_precision {
	    padding-left: 10%;
	}
	.img_carrousel_realisation {
	    height: 510px;
	}
	.paralelle_axe{
		width:30%;
	}
	.gris_avantage {
	    right: -853px;
	}
	.page-template-financement .rouge_avantage {
	    left: -832px;
	}
}
@media screen and (max-width: 1400px) {
	.difference_service .text_difference_slide {
	    bottom: -10px;
	}
	.text_services_difference {
	    padding-right: 2%;
	}
	.diffrence_foreach {
	    max-width: 405px;
	    padding-right: 20px;
	    margin: 0 30px 20px;
	}
	.bottom_dif {
	    bottom: 144px;
	    right: -613px;
	}
	.paralelle_foule {
	    right: -396px;
	}
	.paralelle_axe {
	    width: 25%;
	}
}

@media screen and (max-width: 1300px) {
	.parallel_gris {
	    right: -175px;
	}
	.logo_certifications_1, .logo_certifications_2, .logo_certifications_3, .logo_certifications_4,.logo_certifications_5 {
	    position: relative;
	    top: auto;
	    right: auto;
	    max-height: 250px;
	    max-width: 250px;
	    transform: scale(1);
	}
	.logo_certification_4_texte {
	    position: absolute;
	    right: 30px;
	    top: 72px;
	    height: 180px;
	    width: 192px;
	    text-align: center;
	}
	.logo_certifications_4{
		display: inline-block;
	}
	.text_certifications_propos {
	    width: 576px;
	}
}
@media screen and (max-width: 1200px) {
	.grey_block {
	    right: -500px;
	    margin-top: 25px;
	}
	.red_block {
	    left: -500px;
	    margin-bottom: 25px;
	}
	.approche{
		padding: 10px 5% 0;
		margin-bottom:0;
	}
	.diffrence_flex{
		display: block;
	}
	.check {
	    top: -129px;
	    left: 5%;
	}
	.expertise {
    	width: 100%;
    }
    .diffrence_flex {
	    padding-top: 20px;
	}
	.carrousel_freemode .swiper-slide-active .difference_slide,
	.diffrence_flex .carrousel_freemode .swiper-slide-active {
	    width: 380px!important;
	}
	.parallel_gris {
	    width: 844px;
	    height: 136px;
	    transform: scale(2.5);
	    right: -475px;
	}
	.financement2 {
	    padding: 30px 0 30px 5%;
	}
	.difference_slide {
	    height: 492px;
	}
	.swiper-slide-active .difference_service .text_difference_slide {
	    bottom: 105px;
	}
	.precision .parallelogram_container {
	    max-width: 91%;
	}
	.text_precision,
	.didyouknow{
		position: relative;
		top:initial;
	}
	.precision{
		min-height: auto;
		padding: 50px 0;
	}
	.didyouknow {
	    right: 0%;
	    left: 5%;
	}
	.text_engagement {
	    padding-top: 0;
	}
	.checked_toiture {
	    margin-top: -55px;
	    max-width: 300px;
	}
	.difference_block {
	    text-align: center;
	}
	.difference_titre3{
		margin-bottom: 40px;
	}
	.diffrence_foreach {
	    margin: 0 auto 20px!important;
	    padding-right:0;
	    justify-content:center;
	}
	.slogan {
	    margin: 5% auto 0;
	}
	.flex_distinction {
	    display: block;
	}
	.text_distinction {
	    padding-left: 0px;
	    text-align: center;
	    margin: 0 auto;
	}
	.distinction_logo {
	    margin: 0px auto;
	    display: block;
	}
	.bloc_gris,
	.bloc_rouge{
		flex:0 0 100%;
	}
	.image_row{
		flex: 0 0 50%;
		margin-bottom:20px;
	}
	.image_row,
	.bloc_rouge {
	    margin-left: 0px;
	}
	.flex_row{
		flex-wrap: wrap;
		margin-bottom:0;
	}
	.bloc_rouge{
		padding: 50px 40px 20px;
	}
	.bloc_gris{
		margin-bottom:20px;
	}
	.bloc_rouge img{
		max-width:300px;
		max-height:300px;
	}
	.page-template-toiture .prochangement div {
	    max-width: 550px;
	}
	.page-template-toiture .prochangement h2 {
	    max-width: 80%;
	}
	.text_composantes {
	    padding-left: 8.5%;
	}
	.composantes{
		padding-right:5%;
	}
	.image_row_container{
		padding: 0;
		margin-bottom:20px;
		width:100%;
	}
	.mask_materiaux{
		top:0;
		background-color:$secondary;
		opacity:0.5;
	}
	.text_hover {
	    opacity: 1;
	    visibility: visible;
	    position: absolute;
	    bottom:initial;
		top: 10%!important;
	    padding: 20px 5%;
	}
	.image_row_container_inside:hover .text_hover{
		bottom:initial;
		top: 10%!important;
	}
	.name_materiaux{
		background-color: rgba($primary, 0.6);
	}
	.max_width_bloc_rouge {
	    max-width: 100%;
	    margin-left: 0px;
	    margin-bottom:20px;
	}
	.bloc3_0 {
	    flex: 0 0 100%;
	}
	.image_row_container_inside{
		min-height: 327px;
	}
	.page-template-revetement .image_row_container_inside{
		min-height: 285px;
	}
	.etape_1, .etape_2, .etape_3, .etape_4 {
	    display: block;
	    margin-bottom: 40px;
	}
	.cles {
	    padding: 80px 0 0;
	}
	.img_step {
	    min-height: 542px;
	}
	.step2{
		padding: 80px 5% 0 130px;
	}
	.step3 {
	    padding-top: 60px;
	    padding-right: 5%;
	    padding-bottom: 40px;
	}
	.nouvelles {
	    margin: 60px 0;
	}
	.mobile_disapear{
		display: none;
	}
	.mobile_disappear{
		display: none;
	}
	.mobile_appear{
		display: block;
	}
	.distinction_logo2 {
	    margin: -10px auto;
	    display: block;
	    max-height: 200px;
	}
	.rouge_avantage {
	    top: 0;
	}
	.image_1_president,
	.image_2_president,
	.image_1_president_propos,
	.image_2_president_propos{
		position: relative;
		width: 72%;
		height: 400px;
		left:5%;

		right: initial;
		top:0!important;
	}
	.image_1_president,
	.image_1_president_propos{
		max-width: 309px;
	}
	.suite_president {
	    padding: 100px 5% 0;
	    position: relative;
	}
	.suite_texte {
	    max-width: 100%;
	}
	.signature{
		position: relative;
		bottom: initial;
		margin: 10px 0;
		right:initial;
	}
	.suite_texte{
		margin-bottom: 40px;
	}
	.logo_1_distinctions,
	.logo_2_distinctions,
	.logo_certifications_1,
	.logo_certifications_2,
	.logo_certifications_3,
	.logo_certifications_4{
		position:relative;
		top: initial;
		right: initial;
		max-height:250px;
		max-width:250px;
	}
	.box_rouge_distinctions,
	.box_gris_certifications{
		display: block;
	}
	.text_distinctions_rouge{
		width:100%;
	}
	.distinctions_propos {
	    padding: 80px 5% 50px;
	}
	.text_certifications_propos h2 {
	    margin-top: 0px;
	}
	.approche_propos{
		padding: 0 5%;
	}
	.paralelle_gris {
	    left: 0;
	}
	.page-template-carriere .image_2_president {
	    top: 0px;
	}
	.securite_perspective{
		display: block;
	}
	.securite_perspective img {
	    max-width: 80%;
	    margin: 0 auto;
	    display: block;
	}
	.texte_droite{
		padding-left:10%;
	}
	.avantage{
		display: block;
	}
	.red_avantage{
		padding: 50px 5%;
	}
	.mobile_appear{
		display: block;
	}	
	.mobile_hide{
		display: none;
	}
	.texte_droite,
	.texte_gauche{
		min-height: auto;
	}
	.formulaire{
		padding: 50px 5%;
	}
	.logo_certifications_1, .logo_certifications_2, .logo_certifications_3, .logo_certifications_4 {
	    transform: scale(1);
	}
	.paralelle_axe {
	    width: 20%;
	}
	.pad_text_left,
	.pad_text_right{
		padding: 0;
	}
	footer .no-footer{
		display: none!important;
	}
	.page-template-financement .rouge_avantage {
	    left: -933px;
	}
	.gris_avantage {
	    right: -939px;
	}
	.text_certifications_propos {
	    width: 328px;
	}

}	

@media screen and (max-width: 979px) {
	.pad_slide {
	    padding-left: 5%;
	}
	.img_carrousel2{
		margin:40px 0;
	}
	.branche_expertise{
		padding: 0 5%;
	}
	.financement {
	    padding: 0 5%;
	    top: 0px;
	}
	.financement_logo{
		margin-top:40px;
		max-height:300px;
	}
	.text_financement {
	    margin-top: 50px;
	}
	.qualifie,
	.qualite{
		max-width: 400px;
	}
	.text_financement{
		margin-left:0px;
	}
	.employeur {
	    display: block;
	    margin: 0px 0 50px;
	}
	.text_employeur {
	    width: 100%;
	}
	.img_employeur {
	    width: 100%;
	    height: 644px;
	}
	.blog_solo {
		margin: 0 0 20px;
	}
	.pad_nouvelle {
	    min-height: auto;
	}
	.title_nouvelle {
	    left: 5%;
	}
	.title_nouvelle2 {
	    left: 25px;
	}
	.flex-3{
	   	flex: 0 0 100%;
	}
	.text-blog {
	    padding-right: 0;
	}
	.flex-container, .flexible {
	    display: block;
	}
	.pad-text{
		margin-bottom: 50px;
		padding: 0;
	}
	.padding-content {
	    margin: 50px 0;
	}
	.pad-text-right {
	    padding-left: 0;
	}
	.text_precision {
		max-width: 90%;
	}
	.text_precision .button_parallelogram {
	    padding-left: 15%;
	}
	.flexible_carrousel {
	    display: block;
	}
	.img_carrousel_realisation {
	    height: 510px;
	}
	.logo_rouge {
	    left: initial;
	    right:5%;
	    max-width: 50%;
	}
	.title_map {
	    padding: 4px 80px;
	}
	.flex_team {
	    flex: 0 0 100%;
	}
	.text_axe {
	    padding:0 5%;

	}
	.paralelle_axe {
	    top: -119px;
	}
	.carre_gris_financement{
		bottom: 0;
	}

}

@media screen and (max-width: 640px) {
	.grey_block {
	    right: -300px;
	}
	.red_block {
	    left: -300px;
	}
	.check {
	    top: -75px;
	    max-height: 150px;
	    max-width: 150px;
	}
	.difference {
	    margin: 85px 0 50px;
	}
	.carrousel_freemode .swiper-slide-active{
	    width: 288px!important;
	}
	.carrousel_freemode .swiper-slide-active .difference_slide{
	    width: 100%!important;
	}
	.difference_slide {
	    height: 417px;
	}
	.carrousel_freemode .swiper-slide-active .mask_difference {
	    width: 100%;
	    height: 100%;
	    bottom: 0;
	    left: 0;
	}
	.carrousel_freemode .swiper-slide-active .text_difference_slide {
	    left: 20px;
	    top: initial;
	    bottom: 0;
	    padding-right: 12%;
	    position: absolute;
	    max-width:100%;
	}
	.img_difference,
	.mask_difference{
		height:100%;
	}
	.img_employeur {
	    width: 100%;
	    height: 300px;
	    margin-top:20px;
	}
	.qualifie, .qualite {
	    max-width: 300px;
	}
	.nouvelles .button_carrousel {
	    bottom: 41px;
	}
	.pad_nouvelle {
	    padding: 20px 20px 65px;
	}
	.container_blogue_accueil {
	    padding: 80px 20px 110px;
	}
	.page-template-toiture .container_blogue_accueil {
	    padding: 20px 0px 105px;
	}
	.pros {
	    min-height: 410px;
	}
	.button_carrousel_rouge p {
	    margin-left: 0px;
	}
	.financement_logo {
	    max-height: 200px;
	}
	.img_blog {
	    height: 200px;
	}
	.pad_nouvelle .button {
	    bottom: 30px;
	    left: 20px;
	}
	.prochangement {
	    margin: 50px 0;
	    padding: 0 5%!important;
	}
	.cover p {
	    margin-top: 10px;
	}
	.img_carrousel2 {
	    height: 215px
	}
	.nouvelles {
	    margin: 50px 0;
	}
	.carrousel_freemode .crochet{
		max-height: 45px;
	}
	.carrousel_freemode .swiper-slide-active .crochet {
	    top: 20px;
	    right: 15%;
	    max-height: 45px;
	}
	.pros {
	    margin-top: 50px;
	}
	.prochangement {
	    margin: 50px 0;
	}
	.services_toiture {
	    margin: 120px 0 0;
	}
	.text_precision {
	    max-width: 100%;
	}
	.image_row {
	    flex: 0 0 100%;
	}
	.bloc_gris {
	    padding: 20px;
	}
	.bloc_rouge img {
	    max-width: 245px;
	    max-height: 245px;
	}
	.bloc_rouge {
	    padding: 20px;
	}
	.diffrence_flex {
	    padding-right: 5%;
	}
	.text_financement{
		padding-right:5%;
	}
	.pad_precision {
	    padding: 0 5%;
	}
	.didyouknow {
	    max-width: 95%;
	    left: 5%;
	    margin-top: 60px;
	}
	.img_carrousel_realisation {
	    height: 328px;
	}
	.checked_toiture {
	    margin-top: 0px;
	    max-width: 158px;
	    max-height: 158px;
	}
	.difference_block {
	    margin: 120px 4% 40px;
	}
	.text_distinction {
	    max-width: 90%;
	}
	.materiaux {
	    padding: 50px 5%;
	}
	.composantes {
	    padding: 50px 0 40px 3.5%;
	}
	.text_composantes {
	    padding-left: 0.5%;
	    padding-right:5%;
	}
	.page-template-toiture .prochangement {
	    margin: 50px 0;
	}
	.button_contactez p {
	    margin-left: 0px;
	}
	.distinction_logo {
	    max-width: 200px;
	}
	.text_engagement {
	    padding-left: 9%;
	    padding-right: 5%;
	}
	.etapes{
		padding-right:5%;
	}
	.difference_titre3 {
	    margin: 10px 0;
	}
	.img_step {
	    min-height: 202px;
	}
	.title_cles {
	    margin-left: 5%;
	}
	.same_line {
	    left: 0;
	}
	.step1, .step3 {
	    padding-left: 5%;
	}
	.same_line .chiffre_etape {
	    position: relative;
	    left: 0;
	    top: 0;
	    margin:0;
	}
	.word1,
	.word2,
	.word3{
		clip-path: initial;
	}
	.chiffre_etape {
	    height: 35px;
	    width: 35px;
	}
	.word_red {
	    display: block;
	}
	.word1,
	.word2{
		margin-bottom:10px;
	}
	.word_red p {
	    text-align: center;
	}
	.step2 {
	    padding: 40px 5% 0 5%;
	}
	.gray_back{
		width: 100%;
	}
	.cles {
	    padding: 40px 0 0;
	}
	.step3 {
	    padding-top: 20px;
	}
	.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
	    margin: 0 2%;
	}
	.logo_rouge {
    	position: relative;
    }
    .rouge_avantage {
	    left: -157px;
	    height: 85px;
	}
	.gris_avantage {
	    right: -525px;
	}
	.certifications_propos {
	    padding: 40px 0;
	}
	.box_rouge_distinctions {
	    padding: 50px 6%;
	}
	.suite_president {
	    padding: 50px 5% 0;
	}
	.image_1_president, .image_2_president,
	.image_1_president_propos,.image_2_president_propos {
	    height: 250px;
	}
	.certifications_propos > h2, .distinctions_propos > h2 {
	    padding-left: 0%;
	    letter-spacing: 1px;
	}
	.text_distinctions_rouge h2 {
		margin-bottom: 40px;
	}
	.text_certifications_propos {
	    width: 100%;
	}
	.approche_propos {
	    max-width: 100%;
	    margin: 30px auto;
	}
	.img_personne {
	    height: 200px;
	    width: 100%;
	}
	.description_team {
	    margin-top: 10px!important;
	}
	.team {
	    padding: 30px 5%;
	}
	.title_map {
	    padding: 1px 20px 1px 80px;
    	width: 55%;
	}
	.logo_1_distinctions, .logo_2_distinctions, .logo_certifications_1, .logo_certifications_2, .logo_certifications_3, .logo_certifications_4, .logo_certifications_5{
		margin: 0 auto;
		display: block;
	}
	.paralelle_rouge,
	.paralelle_gris{
		display: none;
	}
	.suite, .texte_suite_container {
	    padding-top: 0px;
	}
	.suite_texte2 {
	    padding-left: 5%;
	}
	.texte_suite {
	    padding: 5%;
	}
	.signature2 {
	    margin: -1px 0 0;
	}
	.securite_perspective {
	    margin-bottom: 40px;
	}
	.img_avantage {
	    height: 209px;
	}
	.text_axe {
	    float: none;
	    margin-bottom: 50px;
	    padding: 0 5%;
	}
	.axe {
	    margin: 10px 0;
	}
	.foule {
	    padding: 30px 0;
	}
	.paralelle_axe {
	    top: -34px;
	}
	.paralelle_axe {
	    display: none;
	}
	.formulaire{
		padding: 20px 5%;
	}
	.logo_materiaux {
	    right: -15%;
	    transform: scale(0.4);
	}
	.page-template-a_propos .suite_president {
	    padding: 0 5%;
	}
	.text_flex_carrousel h3,
	.text_flex_carrousel2 h3{
		margin: 0 0 25px!important;
	}
	.realisation_toiture {
	    padding: 50px 5%;
	}
	.diffrence_foreach{
		min-height: 87px;
	}
	.align{
		display: block;
	}
	.mask_difference{
		background-color: rgba($secondary, 0.5);
	}
	.approche {
	    padding: 10px 5% 50px;
	    margin: 80px 0 0;
	}
	.approche .button_carrousel {
	    margin-top: -155px;
	}
	.mask_difference_rouge{
		height:100%;
	}
	.swiper-expertise .line_text{
		text-shadow: none;
		color: $gray;
	}
	.swiper-expertise .swiper-slide-active .line_text.full{
		color:$primary;
	}
	.etape_titre{
		margin-top:6px;
	}
	.text_hover{
		height:100%;
		top:19%!important;
	}
	.page-template-terrasse_patio .text_hover{
		top:10%!important;
	}
	.pad_step {
	    padding-top: 20px;
	}
	.logo_certifications_4{
		display: block;
	}
}	

@media screen and (max-width: 400px) {
	.grey_block {
	    right: -200px;
	    top:-60px;
	}
	.red_block {
	    left: -200px;
	   	max-height: 124px;
	    margin-bottom:-20px;
	}
	.approche {
	    margin: 30px 0 50px;
	}
	.parallel_gris {
	    transform: scale(4.5);
	    right: -475px;
	}
	.approche .button_carrousel {
	    margin-top: -209px;
	}
	.diffrence_flex .carrousel_freemode .swiper-slide-active {
	    width: 300px!important;
	}
	.carrousel_freemode .swiper-slide-active .crochet {
	    right: 5%;
	}
}	
