.c-soumission {
  &__popup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: none;
    pointer-events: none;
    justify-content: center;
    align-items: center;

    padding: 30px;
    z-index: 99999;

    &.js-isActive {
      display: flex;
      pointer-events: auto;
    }

    &--col {
      display: block;
      width: 100%;
      max-width: 50%;
      padding: 0 30px;

      @media(max-width: 520px) {
        max-width: 100%;
        padding: 0;
        margin-bottom: 30px;
      }
    }

    &--types {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -30px;

      @media(max-width: 520px) {
        margin: 0;
      }

      .item {
        position: relative;
        display: block;
        overflow: hidden;

        &--content {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;

          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          padding: 15px;
          text-align: center;
          width: 100%;
          z-index: 2;

          @media(max-width: 520px) {
            padding: 0;
          }

          h3 {
            color: white
          }
        }

        &--bg {
          position: relative;
          display: block;
          padding-top: 56.25%;
          z-index: 0;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: rgba($black, .7);
            transition: .2s ease-in-out;
          }

          .thumbnail {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;

            background-size: cover;
            background-position: center center;
            z-index: -1;
            transform: scale(1.2);
            transition: transform .2s ease-in-out;
          }
        }

        &:hover {
          .item--bg:before {
            background-color: rgba(#db1a15, .8);
          }
        }
      }
    }

    &--wrapper {
      position: relative;
      display: block;
      width: 100%;
      max-width: 800px;
      padding: 60px;
      background-color: #f0f0f0;

      @media(max-width: 520px) {
        padding: 60px 30px 15px 30px;
      }

      .font40 {
        text-align: center;
        margin: 0 0 60px 0;
      }

      button.close {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        width: 60px;
        height: 60px;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        transition: .2s ease-in-out;

        svg {
          width: 15px;
          transition: .2s ease-in-out;
        }

        &:hover {
          background-color: #db1a15;

          svg {
            filter: invert(1);
          }
        }
      }
    }

    &--background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba($black, .8);
      z-index: -1;
    }
  }

  &__page {
    &--row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media (max-width: 920px) {
        display: block;
      }

      .types {
        max-width: 33.333333%;
        flex: 0 0 33.333333%;
      }
    }
  }
}
