//MIXINS
//Colors
$whity: #ffffff;
$black: #000;

$primary: #DB1A15;
$secondary: #3C3C3B; 

$background: #FFFFFF;

$gray: #3D3D3D;

$graylight: #BCBCBC;

$graydark: #808080;

$graypoint: #747474;

$gold: #D4B260; 

$gray_back: #F0F0F0;

$gray_toiture: #FBFBFB;

$gray_blue: #4E4E4D;