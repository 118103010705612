.prochangement{
	display: flex;
	padding: 0 0 0 15%;
}

.prochangement{
	position: relative;
	min-height: 215px;
	margin:200px 0 100px;
}
.prochangement h2{
	color: $secondary;
	max-width: 767px;
	margin-top:0;
}

.button_contactez{
	background-color: $secondary;
	display: flex;
	height: 100px;
	width:370px;
	margin-top:50px;
	margin-left: 9%;
	padding-left:50px;
}

.button_contactez p{
	color: white;
	text-transform: uppercase;
	margin-top: 38px;
    margin-left: 11px;
    font-weight:600;
}

.button_contactez svg{
	margin-top:18px;
	max-width:65px;
	max-height:65px;
}

.forme_rouge{
	position: absolute;
	top:0;
	right:-600px;
	z-index:-1;
}

.partner{
	background-color: #F0F0F0;
	display: flex;
	align-items: center;
	margin-left: 15%;
	height: 116px;
	width: 85%;
	position: relative;
	z-index:2;
	position:relative;
}

.partner div{
	margin: 10px 10px 0;
}
.partner .part img{
	filter: grayscale(1);
	max-height: 69px;
}

.footer{
	display: flex;
	overflow: hidden;
	min-height: 393px;
}

.red_footer{
	background-color: $primary;
	width: 14%;
    margin-right: 20px;
}

.grey_footer{
	background-color: $secondary;
	width: 85%;
	padding: 80px 40px 100px;
	position:relative;
}

.footer .menu-principal{
	float: right;
}

.footer .menu-principal li a {
    color: white;
}

.footer .menu-principal li{
	margin: 0 25px;
}

.menu_top_footer{
	display: flex;
	float: right;
	margin-top: -60px;
}

.info_footer{
	display: flex;
	margin-top: 170px;
}

.info_footer p{
	color: white;
	opacity:0.4;
	margin-right: 30px;
}

.certi_footer{
	display: flex;
	margin-top: 115px!important;
	margin-left:40px!important;
}

.certi_footer img{
	max-height:162px;
	max-width:152px;
}

.logo_footer{
	position: relative;
    top: 45px;
}

.menu_top_footer .button_blogue {
    color: white;
    padding: 10px 20px;
    border: 1px solid white;
}

.menu_top_footer .button_financement{
	color: $secondary;
	background-color: white;
}

.menu_top_footer .facebook path,
.menu_top_footer .instagram path{
	fill:white!important;
	opacity:0.4;
}

.certi_footer h2{
	color: $primary;
}

footer .linkedin path {
    fill: #8A8A89!important;
}

.copyright_lacosta{
	position: absolute;
	bottom:0;
	left:40px;
	display: flex;
	align-items: end;
}

.lacosta{
	position: relative;
    top: 70px;
}

.copyright{
	color: white;
    opacity: .4;
    margin-right:40px;
    font-size: 13px;
}

.footer .menu-principal .sub-menu {
    background-color: #3c3c3b;
    padding: 10px 5px;
    margin: 0;
    position:absolute;
  	z-index:4;
  	min-width:210px;
}

.footer .menu-item-has-children.open:after {
    transform: rotate(180deg);
}
.footer .menu-item-has-children.open:after, .footer .menu-item-has-children:after {
    content: url(/wp-content/themes/NGV/assets/src/img/arrow-icon-menu-white.svg);
}
.footer .menu-item-has-children:after {
    position: absolute;
    height: 30px;
    width: 10px;
    fill: gray;
    top: -2px;
    right: -17px;
    transition: .5s;
}

@media screen and (max-width: 1800px) {
	.info_footer p {
	    margin-right: 20px;
	}
}

@media screen and (max-width: 1700px) {
	.red_footer {
	    width: 5%;
	}
	.grey_footer {
	    width: 94%;
	}
	.partner {
	    margin-left: 6%;
	    width:94%;
	}
}
@media screen and (max-width: 1560px) {
	.prochangement {
	    padding: 0 0 0 5%;
	    margin: 100px 0;
	}
	.button_contactez {
	    margin-left: 0%;
	}
	.prochangement h2{
		max-width:50%;
	}
	.red_footer {
	    display: none;	
	}
	.grey_footer {
	    width: 100%;
	}
	.partner {
	    margin-left: 0;
	    width:100%;
	}
	.info_footer {
	    margin-top: 140px;
	}
	.menu_top_footer {
	    margin-top: 0;
	}
	.partner div {
	    margin: 10px 5px 0;
	}
	.certi_footer {
	    margin-left: 5px!important;
	    margin-top: 60px!important;
	}
	.certi_footer img{
		width: 100px;
    	height: 100px;
	}
}	

@media screen and (max-width: 1200px) {
	.logo_footer {
	    top: 10px;
	}
	.partner {
	    height: auto;
	    flex-wrap: wrap;
	}
	.certi_footer {
	    margin-top: 10px!important;
	}
	.partner{
		padding: 20px 5%;
	}
	.footer .menu-principal {
	    float: none;
	    display: block;
	    padding: 0;
	}
	.info_footer {
	    margin-top: 40px;
	}
	.menu_top_footer {
	    float: none;
	}
	.menu-principal li {
	    margin: 0 0px 10px;
	}
	.footer .menu-principal .sub-menu {
	   position:initial;
	}
	.footer .menu-item-has-children:after {
	    right: initial;
	    left: 100px;
	}
}
@media screen and (max-width: 979px) {
	.prochangement {
	    display: block;
	}
	.forme_rouge {
	    right: -500px;
	}
	.info_footer, .menu_top_footer {
	    flex-wrap: wrap;
	}
}	

@media screen and (max-width: 640px) {
	.button_contactez {
	    width: 275px;
	    padding-left:20px;
	}    
	.prochangement h2 {
	    max-width: 100%;
	}
	.forme_rouge {
	    top: -78px;
	    right: -370px;
	}
	.certi_footer {
	    display: block;
	}
	.menu_top_footer .soumission_button,
	.menu_top_footer .financement_button,
	.menu_top_footer .blogue_button,
	.menu_top_footer .instagram,
	.menu_top_footer .facebook,
	.menu_top_footer .linkedin{
		margin-top:40px;
	}
	.menu_top_footer .facebook{
		margin-left:0;
	}
	.grey_footer {
	    width: 100%;
	    padding: 40px 5%;
	}
	.logo_footer{
		max-width:50%;
		max-height: 30px;
	}
	.copyright_lacosta{
		position:initial;
		display: block;
		margin-top:40px;
	}
	.lacosta{
		top:0;
		margin-bottom: -100px;
	}
	.button_contactez svg {
	    margin-top: 13px;
	    max-height: 70px;
	}
}	

@media screen and (max-width: 400px) {
	.forme_rouge {
	    right: -280px;
	}
}